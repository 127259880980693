// External imports
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';

// Global imports
import { Branch } from 'assets/models/Branch';
import IProviderPosition from 'assets/models/interfaces/IProviderPosition';
import { ResponseData } from 'assets/models/ResponseData';
import { LiveViewData } from 'assets/types/live-view-data';
import { environment } from 'environments/environment';

// Local imports
import { convertToQueryParams } from '../../../../app/helpers/api-services.helper';
import { CustomerContract, UpdateCustomerContractParams } from '../../../../app/helpers/CustomersETLClient';
import { AuthenticationService } from '../auth';
import { IAnswersSurvey } from '../../../../assets/models/Service';
import { ICreateStripeCustomer, IVerifyStripeCreditCard } from 'modules/payment/dispatch-payment/types';
import { OsrmResponse } from './types';

const url: string = environment.apiLink;
const salesUrl: string = environment.salesApiLink;

// TODO: Skip transformation. Make this UI use backend data structure directly
// 	so as to simplify representations.
export function transformDrivers(data: any, connectedDrivers: boolean): IProviderPosition {
	return {
		id: data.id,
		lat: data.lat,
		lng: data.lng,
		salesforceId: data.provider?.salesforce, // of provider
		email: data.email,
		driverId: data._id,
		driverName: data.name,
		phone: data.phone,
		inspectorId: data.inspectorId,
		vendorId: data.provider?.vendorId,
		skills: data.skills, // for filtering
		address: data?.address,

		providerName: data.provider?.name,
		assignedServices: data.assignedServices,
		providerEmail: data.provider?.email,

		// For displaying marker:
		skillDisplay: true,
		iconset: data.iconset,
		vehicleType: data.vehicleType,
		tripStatus: data.tripStatus || 'pending',
		status: data.status || 'active',
		servicesCount: data.servicesCount,
		initials: data.email.substring(0, 2),

		// For displaying on-hover panel
		activeId: data.activeId,
		providerPhone: data.provider?.phone,

		// These are needed for AutoDispatch. When AutoDispatch fetches
		// 	driver/provider information directly from Helios, we can remove.
		accounts: data.provider?.accounts || [],
		branch: data.branch,
		providerId: data.provider?._id,
		providerType: data.provider?.providerType,
		connected: connectedDrivers,
	};
}

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	constructor(private http: HttpClient, private auth: AuthenticationService) {}

	private getRequestOptions(params?: Record<string, any>): { headers: HttpHeaders; params: HttpParams } {
		let headers = new HttpHeaders();
		let parameters = new HttpParams();

		const token: string = this.auth.getAuthToken() ? this.auth.getAuthToken() : 'Token';
		const userId = this.auth.getUserId();
		headers = headers.set('Content-Type', 'application/json').set('Authorization', token);
		if (userId) {
			headers = headers.set('User-Id', userId);
		}
		parameters = convertToQueryParams(params);

		return { headers, params: parameters };
	}

	private get(URL: string, params?: any): Observable<any> {
		return this.http.get(URL, this.getRequestOptions(params));
	}
	private post(URL: string, data?: any): Observable<any> {
		return this.http.post(URL, data, this.getRequestOptions());
	}
	private put(URL: string, data: any): Observable<any> {
		return this.http.put(URL, data, this.getRequestOptions());
	}
	private patch(URL: string, data: any): Observable<any> {
		return this.http.patch(URL, data, this.getRequestOptions());
	}
	private delete(URL: string): Observable<any> {
		return this.http.delete(URL, this.getRequestOptions());
	}
	protected getAsObservable<T = ResponseData>(URL: string, params: any = undefined): Observable<T> {
		return this.http.get<T>(URL, this.getRequestOptions(params));
	}

	getTrafficEta(lat1: number, lng1: number, origins: Array<any>): Observable<any> {
		const destination: string = `${lat1},${lng1}`;
		return this.post(`${url}/maps/directions/trafficEta`, { origins: origins, destination: destination }).pipe(
			map((response: any) => response)
		);
	}

	getAccounts(branch?: string): Observable<any> {
		// from Mongo
		return this.get(`${url}/accounts/all${branch ? '?branch=' + branch : ''}`).pipe(map((response: any) => response));
	}

	getPolicies(accountId): Promise<ResponseData<string[] | undefined>> {
		return this.get(`${url}/accounts/${accountId}/policies`).toPromise();
	}

	updateServiceSurveyScores(
		answers: IAnswersSurvey,
		mapQuestions: IAnswersSurvey,
		serviceId: string,
		observations: string
	): Promise<ResponseData<undefined>> {
		return this.patch(`${url}/surveys`, { answers, mapQuestions, serviceId, observations }).toPromise();
	}

	cancelHoldServices(
		id: string,
		status: string,
		cancellationStatus: string,
		reason: string,
		sfId: string
	): Observable<any> {
		return this.get(`${url}/dispatch/cancelHoldService/${id}/${status}/${cancellationStatus}/${reason}/${sfId}`).pipe(
			map((response: any) => response)
		);
	}

	newSearch(search: string, account: string, branch?: string): Observable<any> {
		return this.get(`${url}/api/v1/dispatch/contracts/?branch=${branch}&search=${search}&account=${account}`).pipe(
			map((response: any) => response.data)
		);
	}

	getContact(sfId: string): Promise<any> {
		return this.get(`${url}/dispatch/vehicleServices/${sfId}`)
			.pipe(map((response: any) => response))
			.toPromise();
	}

	getServiceHistory(customerContract: CustomerContract, allHistoric?: boolean): Observable<any> {
		const object = {
			contractId: String(customerContract.contract_id),
			accountId: customerContract.account.salesforce_id,
			policyType: customerContract.policy.object_type,
			vin: customerContract.vehicle?.vin,
			policySymbol: customerContract.policy.symbol,
		};
		if (allHistoric) {
			object['allHistoric'] = true;
		}
		if (customerContract.extras?.mbi) {
			object['mbi'] = customerContract.extras.mbi;
		}
		if (customerContract.vehicle?.plate) {
			object['plate'] = customerContract.vehicle.plate;
		}
		if (customerContract.sf_client_id) {
			object['sfId'] = customerContract.sf_client_id; // for legacy integrations
		}
		if (customerContract.effective_date) {
			object['startDate'] = customerContract.effective_date;
		}
		if (customerContract.expiration_date) {
			object['endDate'] = customerContract.expiration_date;
		}
		if (customerContract.policy.number) {
			object['policyNumber'] = customerContract.policy.number;
		}
		if (customerContract.policy.unit) {
			object['policyUnit'] = customerContract.policy.unit;
		}
		if (customerContract.customer.id_card) {
			object['idCard'] = customerContract.customer.id_card;
		}
		if (customerContract.customer.first_name && customerContract.customer.last_name) {
			object['firstname'] = customerContract.customer.first_name;
			object['lastname'] = customerContract.customer.last_name;
		}
		if (customerContract.extras.petName) {
			object['petName'] = customerContract.extras.petName;
		}
		const params = new HttpParams({
			fromObject: object,
		});
		return this.get(`${url}/api/v1/dispatch/vehicle/services?${params.toString()}`).pipe(
			map((response: any) => response.data)
		);
	}

	geoCode(lat: number, lon: number): Observable<any> {
		return this.http
			.get(
				'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
					lat +
					',' +
					lon +
					`&sensor=false&key=${environment.GOOGLE_MAPS_KEY}`
			)
			.pipe(map((response: any) => response));
	}

	getCity(zipCode: string): Observable<any> {
		return this.http.get('https://api.zippopotam.us/PR/' + zipCode).pipe(map((response: any) => response));
	}

	// Send Text
	sendText(data: any): Observable<any> {
		return this.post(url + '/dispatch/sms', data);
	}

	// Create Stripe Customer
	cStripeCustomer(data: ICreateStripeCustomer): Observable<IVerifyStripeCreditCard> {
		return this.post(url + '/dispatch/cCustomer', data).pipe(map((response: any) => response));
	}

	// Dispatch Service
	dispatch(data: any): Observable<any> {
		return this.post(url + '/services/dispatch', data).pipe(map((response: any) => response));
	}

	// Dispatch Service
	dispatchEdit(data: any): Observable<any> {
		return this.post(url + '/services/dispatchEdit', data).pipe(map((response: any) => response));
	}
	// Update Hold Service Info
	dispatchHold(data: any): Observable<any> {
		return this.post(url + '/services/dispatchHold', data).pipe(map((response: any) => response));
	}

	// Add Susbscription to an existing Stripe Customer
	createSubscriptionToExistingStripeCustomer(data: any): Observable<any> {
		return this.post(salesUrl + '/sales/createSubscriptionToExistingStripeCustomer', data).pipe(
			map((response: any) => response)
		);
	}

	// Cancel Service Road
	cancelService(data: any): Observable<any> {
		return this.post(url + '/services/cancel', data).pipe(map((response: any) => response));
	}

	// Edit Service
	editFinishedService(data: any): Observable<any> {
		return this.patch(url + '/services/finished', data).pipe(map((response: any) => response));
	}

	editHomeService(data: any): Observable<any> {
		return this.post(url + '/services/editHomeService', data).pipe(map((response: any) => response));
	}

	updateEditOrDuplicateStatus(data: any): Observable<any> {
		return this.post(url + '/dashboard/updateEditOrDuplicateStatus', data).pipe(map((response: any) => response));
	}

	// Add Notes
	addNotes(data: any): Observable<any> {
		return this.post(url + '/utils/addNote', data).pipe(map((response: any) => response));
	}

	// update quien Auditó
	updateAuditedBy(data: any): Observable<any> {
		return this.post(url + '/dashboard/updateAuditedBy', data).pipe(map((response: any) => response));
	}

	public getDriverLocation(driverId: string) {
		return this.get(`${url}/providers/drivers/driverById/${driverId}/location`).toPromise();
	}

	async getMapDrivers(branch: Branch, bounds?: google.maps.LatLngBoundsLiteral) {
		const params = { branch: branch };
		if (bounds) {
			params['bottomLeft'] = `${bounds.south},${bounds.west}`;
			params['topRight'] = `${bounds.north},${bounds.east}`;
		}
		const response = await this.get(url + '/api/v1/map/drivers', params).toPromise();
		return response.data;
	}

	async getActiveServices(branch?: Branch) {
		return await this.get(`${url}/api/v1/drivers/active-services`, { branch }).toPromise();
	}

	// Update User
	addServiceTimestamp(data: any): Observable<any> {
		return this.post(url + `/service/trip/${data._id}/timestamp?dashboard=true`, data).pipe(
			map((response: any) => response)
		);
	}

	updateInspectionService(data: any): Promise<any> {
		return this.post(`${url}/services/holdEditService`, data).toPromise();
	}

	public getServiceInfo(serviceId: string) {
		return this.get(`${url}/services/${serviceId}`).toPromise();
	}

	public getCallInfo(callUniqueId: string): Promise<any> {
		const params = new HttpParams().set('callUniqueId', callUniqueId);
		return this.get(`${url}/api/v1/incoming-calls/get-data?callUniqueId=${callUniqueId}`, params).toPromise();
	}

	public getClientInfo(callUniqueId: string): Promise<any> {
		const observable = this.getAsObservable(`${url}/api/v1/incoming-calls/${callUniqueId}/client-info`);
		return lastValueFrom(observable);
	}

	// Get Services by status and branch
	public getServices(params: any): Promise<ResponseData> {
		return this.get(`${url}/api/v1/services`, params).toPromise();
	}

	/**
	 * GetServerTime()
	 * Returns the timestamp from the server
	 */
	public async getServerTime(): Promise<{ status: true; data: number }> {
		return await this.get(`${url}/time`).toPromise();
	}

	/**
	 * Get users from Live View
	 */
	public async getLiveData(): Promise<Record<string, LiveViewData[]>> {
		const response = await this.get(`${url}/dashboard/liveViewData`).toPromise();
		return response.data;
	}

	/**
	 * Remove users from Live View
	 */
	public async removeLiveData(agentId: string, serviceNumber: number): Promise<any> {
		return await this.delete(`${url}/dashboard/liveViewData/${agentId}/${serviceNumber}`).toPromise();
	}

	/**
	 * Add users to Live View
	 */
	public async addUserLiveData(agentId: string, serviceNumber: number): Promise<any> {
		return await this.post(`${url}/dashboard/liveViewData/${agentId}/${serviceNumber}`).toPromise();
	}

	/**
	 * Get Etl Data Definition
	 */
	public async getEtlDataDefinition(accountId: string, suggestions: boolean = false): Promise<any> {
		return await this.get(`${url}/api/etl/definition?accountId=${accountId}&suggestions=${suggestions}`).toPromise();
	}

	/**
	 * Set Etl Data Definition
	 */
	public async setEtlDataDefinition(accountId: string, data: any): Promise<any> {
		return await this.post(`${url}/api/etl/definition?accountId=${accountId}`, data).toPromise();
	}

	/**
	 * Set Etl Data Definition
	 */
	public async generateEtlDataDefinition(data: any): Promise<any> {
		let headers = new HttpHeaders();
		const token: string = environment.ETL_DEFINITIONS_TOKEN;
		headers = headers.set('Authorization', token);
		return this.http
			.post(
				`${environment.ETL_DEFINITIONS_BASE_URL}/inference`,
				{
					prompt: 'instructions-generation',
					user_input: data,
				},
				{
					headers,
				}
			)
			.toPromise();
	}

	public async saveAutoDispatchSuggestion(service: any, suggestionUsed: boolean, debugData: any, trueEta: number) {
		const payload = {
			serviceId: service._id,
			finalProviderVendorId: service.provider.id,
			finalDriverId: service.driver.id || service.driver._id,
			suggestionUsed,
			debugData,
			branch: service.branch,
			serviceDate: service.date,
			providerName: service.providerName,
			serviceNumber: service.serviceNumber,
			trueEta: trueEta,
		};
		return await this.post(`${url}/api/v1/autodispatch-results`, payload).toPromise();
	}

	/**
	 * Endpoint to retrieve a service's images
	 * from Connect Services API
	 * @param serviceNumber -> The service's serviceNumber to retrieve the media from
	 */
	public async getServiceImages(
		serviceNumber: number,
		bucket: string = undefined,
		region: string = undefined
	): Promise<any> {
		if (bucket && region) {
			return await this.get(
				`${environment.connectServicesUrl}/api/v1/media/${serviceNumber}?bucketName=${bucket}&bucketRegion=${region}`
			).toPromise();
		}
		return await this.get(`${environment.connectServicesUrl}/api/v1/media/${serviceNumber}`).toPromise();
	}

	public async updateCustomerContract(contract: UpdateCustomerContractParams): Promise<any> {
		return await this.patch(`${url}/api/v1/dispatch/contracts`, contract).toPromise();
	}

	public async getDiscounts(params: any): Promise<any> {
		return await this.get(`${url}/api/v2/services/discounts/applicables`, params).toPromise();
	}

	public async getAuditReasons(): Promise<any> {
		return await this.get(`${url}/api/audit-reasons`).toPromise();
	}

	public async clickToCall(serviceId: string, destination: string): Promise<any> {
		const formattedPhone = destination?.replace(/[^\d]/g, '');
		let makeCallWithAudara = false;

		const wolkvoxUrl = `http://localhost/apiagentbox?action=dial&phone=9${formattedPhone}&id_customer=${formattedPhone}`;

		// Check if Wolkvox is up to make the call
		try {
			await fetch(wolkvoxUrl);
		} catch (error) {
			makeCallWithAudara = true;
		}

		// Save call in log and make call through Audara if Wolkvox is down
		return await this.post(`${url}/services/${serviceId}/click-to-call`, {
			destination: formattedPhone,
			makeCallWithAudara,
		}).toPromise();
	}

	fetchDistanceMatrixOSRM(locations: { lng; lat }[]): Observable<OsrmResponse> {
		const coordinates = locations.map((location) => `${location.lng},${location.lat}`).join(';');
		const url = `${environment.OSRM_BASE_URL}/route/v1/driving/${coordinates}`;
		const params = {
			annotations: 'true',
			overview: 'full',
			geometries: 'geojson',
			steps: 'true',
			continue_straight: 'true',
		};
		const response = this.http.get(url, { params });
		return response.pipe(map((response: OsrmResponse) => response));
	}
}
